import { useQuery } from '@tanstack/react-query'
import classnames from 'classnames/bind'
import { Elements } from 'prismic-reactjs'
import { useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { TRACKING_EVENTS } from '~/lib/constants'
import { getProductById } from '~/lib/get-product-by-handle'

import { useTimeline } from '@unlikelystudio/react-gsap-hooks'
import {
  useIsomorphicLayoutEffect,
  useIsTouchScreen,
} from '@unlikelystudio/react-hooks'

import ImageWithPlaceholder from '~/components/ImageWithPlaceholder'
import Link from '~/components/Link'
import Ratio from '~/components/Ratio'
import RichText, { prismicRichTextAsText } from '~/components/RichText'
import Spinner from '~/components/Spinner'

import { useStyle } from '~/providers/StyleProvider'
import { useTracking } from '~/providers/TrackingProvider'

import useLocale from '~/hooks/useLocale'

import getAriaLabelProp from '~/utils/get-aria-label-prop'
import { addGIDPrefix } from '~/utils/shopify-ids'

import serializePrice from '~/data/serialize-price'

import css from './styles.module.scss'
import { ProductCardProps } from './types'

const cx = classnames.bind(css)

const PRODUCT_CARD_IMAGE_RATIO = 315 / 420

const DEFAULT_ALT = 'Product card image'

function ProductCard({
  className,
  ratio,
  animated = false,
  visible = false,
  id,
  variants,
  isPaired,
  priority,
  trackingData,
}: ProductCardProps) {
  const tracking = useTracking()
  const t = useTranslate()
  const [variantIndex, setVariantIndex] = useState<number | null>(null)
  const [isHover, setIsHover] = useState<boolean>(false)
  const productNameRef = useRef<HTMLAnchorElement>(null)
  const productPriceRef = useRef<HTMLDivElement>(null)

  const isTouchScreen = useIsTouchScreen()
  const canHover = !isTouchScreen

  const currentVariant =
    variantIndex === null ? variants?.[0] : variants?.[variantIndex]

  const productNameTextStyle = useStyle({
    textPreset: 'card-12-14',
    color: 'black',
  })

  const priceTextStyle = useStyle({
    textPreset: 'card-11-13',
    color: 'gray-67',
  })

  useEffect(() => {
    if (!isHover) setVariantIndex(null)
  }, [isHover])

  const hasMultipleColors = variants?.length > 1

  const locale = useLocale()

  const { data: currentVariantPrice, isFetching: priceIsLoading } = useQuery(
    ['fetch_current_variant_price', id, `${currentVariant?.shopifyId}`],
    async () => {
      if (currentVariant?.variantId || currentVariant?.variantIds) return null

      const shopifyData = await getProductById({
        id: addGIDPrefix('Product', Number(currentVariant?.shopifyId)),
        withInventory: false,
      })

      const minVariantPrice = shopifyData?.priceRange?.minVariantPrice

      const hasPriceRange =
        shopifyData?.priceRange?.maxVariantPrice?.amount !==
        minVariantPrice?.amount

      const formattedPrice = serializePrice(
        locale,
        minVariantPrice?.currencyCode,
        minVariantPrice?.amount * (isPaired ? 2 : 1),
      )

      return hasPriceRange
        ? t('product.price_range', {
            min: formattedPrice,
          })
        : formattedPrice
    },
    {
      keepPreviousData: true,
      staleTime: 300000,
      enabled: Boolean(currentVariant),
    },
  )

  const price = currentVariantPrice
    ? currentVariantPrice
    : currentVariant?.priceRange
    ? t('product.price_range', {
        min: currentVariant?.priceRange?.min ?? currentVariant?.formattedPrice,
      })
    : currentVariant?.formattedPrice

  const linkProps = {
    onClick: () => {
      tracking.emit(TRACKING_EVENTS.SELECT_ITEM, trackingData)
    },
    ...currentVariant?.link,
    ...(currentVariant?.name &&
      getAriaLabelProp(prismicRichTextAsText(currentVariant.name))),
  }

  const nameAsText = prismicRichTextAsText(currentVariant?.name)

  const variantThumbnails = (
    <div className={css.variants}>
      {variants?.map((currentVariant, index) => {
        return (
          <Link
            key={`variant${currentVariant?.id}`}
            className={cx(css.variant, {
              active:
                variantIndex === index ||
                (variantIndex === null && index === 0),
            })}
            onMouseEnter={() => setVariantIndex(index)}
            {...linkProps}>
            {currentVariant?.image && (
              <ImageWithPlaceholder
                layout="fill"
                objectFit="cover"
                objectPosition="bottom"
                className={className}
                quality={90}
                sizesFromBreakpoints={[
                  { breakpoint: 'md', columns: 1 },
                  { columns: 1 },
                ]}
                {...currentVariant.image}
                alt={currentVariant.image?.alt ?? nameAsText ?? DEFAULT_ALT}
              />
            )}
          </Link>
        )
      })}
    </div>
  )

  const productInfos = (
    <>
      {(!isHover || !hasMultipleColors) && (
        <Link
          ref={productNameRef}
          className={cx(css.productName, productNameTextStyle)}
          {...linkProps}>
          {currentVariant?.name && (
            <RichText
              className={css.type}
              render={currentVariant?.name?.map((name) => ({
                ...name,
                type: Elements.paragraph,
              }))}
            />
          )}
          {currentVariant?.material && (
            <p className={cx(css.hideOnSmallScreen, css.productMaterial)}>
              {currentVariant.material}
            </p>
          )}
          <p className={cx(css.hideOnLargeScreen, css.productMaterial)}>
            {t('product.color_amount', { amount: variants?.length })}
          </p>
        </Link>
      )}
      {isHover && hasMultipleColors && variantThumbnails}
      <div
        ref={productPriceRef}
        className={cx(css.productPrice, priceTextStyle)}>
        {priceIsLoading && isHover && (
          <span className={css.spinnerWrapper}>
            <Spinner className={css.spinner} />
          </span>
        )}
        {price && !priceIsLoading && price}
      </div>
    </>
  )

  const tl = useTimeline(
    { paused: true, restoreTime: true },
    (tl) => {
      if (animated) {
        const group = [productNameRef, productPriceRef]
          ?.filter((ref) => ref.current)
          ?.map((ref) => ref.current)

        tl.fromTo(
          group,
          { opacity: 0 },
          {
            opacity: 1,
            duration: 0.35,
            stagger: 0.05,
            delay: 0.2,
            ease: 'none',
          },
        )

        tl.fromTo(
          group,
          { xPercent: 10 },
          {
            xPercent: 0,
            duration: 0.35,
            stagger: 0.05,
            ease: 'expo.out',
          },
          '<',
        )
      }
    },
    [],
  )

  useIsomorphicLayoutEffect(() => {
    if (animated && visible) tl.restart()
  }, [visible, animated])

  const sizesFromBreakpoints = [
    { breakpoint: 'md', columns: 3 },
    { columns: 4 },
  ]

  return (
    <div className={cx(className, css.ProductCard, { isHover: isHover })}>
      <div
        className={css.wrapper}
        onMouseEnter={() => canHover && setIsHover(true)}
        onMouseLeave={() => canHover && setIsHover(false)}>
        <Ratio
          className={css.productImage}
          ratio={ratio ? ratio : PRODUCT_CARD_IMAGE_RATIO}>
          {(className) => {
            const processedImage = (
              <>
                <Link className={cx(className, css.mainImage)} {...linkProps}>
                  {/* Default image when non hovered or hovered and no image hover contributed */}
                  {((!isHover && variantIndex === null) ||
                    (isHover &&
                      variantIndex === null &&
                      !currentVariant?.imageHover)) && (
                    <ImageWithPlaceholder
                      layout="fill"
                      objectFit="cover"
                      className={className}
                      quality={75}
                      sizesFromBreakpoints={sizesFromBreakpoints}
                      priority={priority}
                      {...currentVariant?.image}
                      alt={
                        currentVariant?.image?.alt ?? nameAsText ?? DEFAULT_ALT
                      }
                    />
                  )}
                  {/* Default image hovered */}
                  {isHover &&
                    variantIndex === null &&
                    currentVariant?.imageHover && (
                      <ImageWithPlaceholder
                        layout="fill"
                        objectFit="cover"
                        className={className}
                        quality={75}
                        priority={true}
                        sizesFromBreakpoints={sizesFromBreakpoints}
                        {...currentVariant?.imageHover}
                        alt={
                          currentVariant?.imageHover?.alt ??
                          nameAsText ??
                          DEFAULT_ALT
                        }
                      />
                    )}
                  {/* Image hovered for variant */}
                  {isHover && variantIndex !== null && (
                    <ImageWithPlaceholder
                      layout="fill"
                      objectFit="cover"
                      className={className}
                      quality={75}
                      priority={true}
                      sizesFromBreakpoints={sizesFromBreakpoints}
                      {...currentVariant?.image}
                      alt={
                        currentVariant?.image?.alt ?? nameAsText ?? DEFAULT_ALT
                      }
                    />
                  )}
                </Link>
                <div className={cx(className, css.mainImage, css.mobileImage)}>
                  <Link {...linkProps}>
                    <ImageWithPlaceholder
                      layout="fill"
                      objectFit="cover"
                      className={className}
                      quality={75}
                      sizesFromBreakpoints={sizesFromBreakpoints}
                      {...variants?.[0]?.image}
                      alt={
                        variants?.[0]?.image?.alt ?? nameAsText ?? DEFAULT_ALT
                      }
                    />
                  </Link>
                </div>
              </>
            )
            return <div>{processedImage}</div>
          }}
        </Ratio>
        <div className={css.productInfo}>{productInfos}</div>
      </div>
    </div>
  )
}

export default ProductCard
